<template>
  <div class="main">
    <el-form :label-position="'left'" label-width="80px" style="margin: 20px; width: 600px">
      <el-form-item label="提示">
        没有的填 无
      </el-form-item>
      <el-form-item label="日期">
        <el-input v-model="invoice.day"></el-input>
      </el-form-item>
      <el-form-item label="金额（四舍五入到元）">
        <el-input v-model="invoice.num"></el-input>
      </el-form-item>
      <el-form-item label="产品名">
        <el-input v-model="invoice.product"></el-input>
      </el-form-item>
      <el-form-item label="购买渠道">
        <el-input v-model="invoice.from"></el-input>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input v-model="invoice.orderId"></el-input>
      </el-form-item>
      <el-form-item label="发票号">
        <el-input v-model="invoice.invoiceId"></el-input>
      </el-form-item>
      <el-form-item label="发票号">
        <el-input v-model="invoice.invoiceSubId"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getFinalStr">生成文件名</el-button>
      </el-form-item>
      <el-form-item label="最终文件名">
        <el-input v-model="finalStr"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import msgLogger from "@/util/msgLogger";

export default {
  components: {},
  data() {
    return {
      invoice: {
        day: "2023-12-01",
        num: "10",
        product: "R5主机",
        from: "pdd迪兰官方旗舰店",
        orderId: "311342432423432",
        invoiceId: "",
        invoiceSubId: "",
      },
      finalStr: "",
    };
  },
  methods: {
    getFinalStr() {
      this.finalStr =
        this.invoice.day +
        "_" +
        this.invoice.num +
        "_" +
        this.invoice.product +
        "_" +
        this.invoice.from +
        "_" +
        this.invoice.orderId +
        "_" +
        this.invoice.invoiceId +
        "-" +
        this.invoice.invoiceSubId;
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  .box-card {
    text-align: left;
    margin: 20px 0px;
  }
  .button-row {
    text-align: right;
    margin: 20px;
  }
  .table {
    width: 90%;
  }
}
</style>
